<!-- 柱子图 -->
<template>
  <div class="bar-container">
    <div style="width: 100%;">
      <el-select class="charts" v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 图表 -->
      <div class="body">
        <div style="display:flex;flex-direction: column;width: 72%;">
          <div
            :style="
              `overflow: hidden;height:${
                value == 1 ? '425px' : 0
              };width: 100%;`
            "
          >
            <div :id="barMain[0]" class="barMain" style="height: 425px;width: 100%;"></div>
          </div>
          <div
            :style="
              `overflow: hidden;height:${
                value == 2 ? '425px' : 0
              };`
            "
          >
            <div :id="barMain[1]" class="barMain" style="height: 425px;"></div>
          </div>
        </div>
        <!-- v-if 销售--> 
        <div v-if="barMain[0] == 'pro1'" class="shopNum">
          <div class="publicTit">项目服务中的各平台店铺数</div>
          <ul class="con">
            <li class="con-item">
              <div>
                <img src="@/assets/images/TB.png" class="logo" /><span
                  >淘宝店铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'taobaoShopNum',
                      '淘宝服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        { name: '服务时间', colProp: ['startDate', 'endDate'] }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.taobaoShopNum }}</el-button
              >
            </li>
            <li class="con-item">
              <div>
                <img src="@/assets/images/TM.png" class="logo" /><span
                  >天猫店铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'tianMaoShopNum',
                      '天猫服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        { name: '服务时间', colProp: ['startDate', 'endDate'] }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.tianMaoShopNum }}</el-button
              >
            </li>
            <li class="con-item">
              <div>
                <img src="@/assets/images/JD.png" class="logo" /><span
                  >京东店铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'JDShopNum',
                      '京东服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        {
                          name: '服务时间',
                          colProp: ['startDate', 'endDate']
                        }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.JDShopNum }}</el-button
              >
            </li>
            <li class="con-item">
              <div>
                <img src="@/assets/images/PXX.png" class="logo" /><span
                  >拼多多店铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'pinduoduoShopNum',
                      '拼多多服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        {
                          name: '服务时间',
                          colProp: ['startDate', 'endDate']
                        }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.pinduoduoShopNum }}</el-button
              >
            </li>
            <li class="con-item">
              <div>
                <img src="@/assets/images/DY.png" class="logo" /><span
                  >抖音铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'tiktokShopNum',
                      '抖音服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        {
                          name: '服务时间',
                          colProp: ['startDate', 'endDate']
                        }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.tiktokShopNum }}</el-button
              >
            </li>
            <li class="con-item">
              <div>
                <img src="@/assets/images/KS.png" class="logo" /><span
                  >快手店铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'quickWorkerShopNum',
                      '快手服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        {
                          name: '服务时间',
                          colProp: ['startDate', 'endDate']
                        }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.quickWorkerShopNum }}</el-button
              >
            </li>
            <li class="con-item">
              <div>
                <img src="@/assets/images/Other.png" class="logo" /><span
                  >其他平台店铺数</span
                >
              </div>
              <el-button
                @click="
                  () => {
                    collect(
                      'otherShopNum',
                      '其他平台服务中店铺数',
                      [
                        {
                          name: '所属客户',
                          type: 'input',
                          modelKey: 'customerName'
                        }
                      ],
                      [
                        { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                        { name: '所属客户', colProp: ['customerName'] },
                        {
                          name: '服务时间',
                          colProp: ['startDate', 'endDate']
                        }
                      ]
                    );
                  }
                "
                type="text"
                class="pubTextBtn"
                >{{ rightData.otherWorkerShopNum }}</el-button
              >
            </li>
          </ul>
        </div>
        <!-- v-else 财务-->
        <div v-else class="shopNum2">
          <ul>
            <li style="display: flex;align-items: center;">
              <span class="left">累计收入</span
              ><span class="right"
                ><span class="mon">本年度收入总金额</span
                ><el-button
                  @click="
                    () => {
                      collect(
                        'incomeTotal',
                        '本年度收入总金额',
                        [],
                        [
                          { name: '流水编号', colProp: ['code'] },
                          { name: '收入金额', colProp: ['amount'] },
                          { name: '收入状态', colProp: ['status'] },
                          { name: '收入类型', colProp: ['expenditureTypeName'] },
                          { name: '收款人', colProp: ['transactionName'] },
                          { name: '收入方式', colProp: ['recordType'] },
                          { name: '收入账户', colProp: ['transactionAccount'] },
                          { name: '备注', colProp: ['remark'] }
                        ],
                        'income'
                      );
                    }
                  "
                  type="text"
                  class="money"
                  >￥{{ rightData.incomeTotal.toFixed(2) }}</el-button
                ></span
              >
            </li>
            <li style="display: flex;align-items: center;">
              <span class="left2">累计支出</span
              ><span class="right2"
                ><span class="mon">本年度支出总金额</span
                ><el-button
                  @click="
                    () => {
                      collect(
                        'expenditureTotal',
                        '本年度支出总金额',
                        [],
                        [
                          { name: '流水编号', colProp: ['code'] },
                          { name: '出账金额', colProp: ['amount'] },
                          { name: '出账状态', colProp: ['status'] },
                          { name: '出账类型', colProp: ['expenditureType'] },
                          { name: '收款人', colProp: ['transactionName'] },
                          { name: '出账方式', colProp: ['recordType'] },
                          { name: '出账账户', colProp: ['transactionAccount'] },
                          { name: '备注', colProp: ['remark'] }
                        ],
                        'payout'
                      );
                    }
                  "
                  type="text"
                  class="money"
                  >￥{{ rightData.expenditureTotal.toFixed(2) }}</el-button
                ></span
              >
            </li>
            <li style="display: flex;align-items: center;">
              <span class="left">利润</span
              ><span class="right"
                ><span class="mon">毛利润总金额</span
                ><span class="money3"
                  >￥{{ rightData.profitMonthTotal.toFixed(2) }}</span
                ></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="
        barMain[0] == 'pro1' ? getMarketReportList : getFinanceReportDetail
      "
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";
import {
  getMarketReportList,
  getFinanceReportDetail
} from "../../../service/home.js";
// import { Config } from "../../../utils/index.js";
import elementResizeDetectorMaker from "element-resize-detector"; // 尺寸监听组件

export default {
  name: "",
  components: { CommonSumDialog },
  props: ["homeData", "options1", "barMain", "rightData","activeRadio","comId"],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getMarketReportList, // 列表接口方法
      getFinanceReportDetail, // 列表接口方法
      params:{},
      activeName: "first",
      value: "1",
      option: {
        width: '92%',
        color: "#1890ff",
        tooltip: { 
          trigger: "axis",
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            //---坐标轴 刻度
            show: false //---是否显示
          },
          axisLine: {
            //隐藏刻度线
            show: false
          },
          axisLabel: {
            //X轴刻度坐标文字样式
            interval:0, //设置为0强制显示所有标签，如果设置为1，表示隔一个标签显示一个标签
            textStyle: {
              fontSize: 14,
              color: "#999"
            }
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            //---坐标轴 刻度
            show: false //---是否显示
          },
          axisLine: {
            //隐藏刻度线
            show: false
          },
          axisLabel: {
            //Y轴刻度坐标文字样式
            textStyle: {
              fontSize: 14,
              color: "#999"
            },
            formatter: this.barMain[0] == "barMain2" ? "{value} %" : "{value}"
          }
        },
        legend: {
          show: true,
          data: [], //图例，series里name
          right: "100"
        },
        grid: {
            left: "9%",
            right: "6%",
            bottom: "6%",
            containLabel: false //---grid 区域是否包含坐标轴的刻度标签
        },
        series: [
          // {
          //   name: "项目服务每月总咨询量",
          //   data: [],
          //   type: "bar",
          //   barWidth: 40 //柱图宽度
          // }
        ]
      },
      arr: [],
      arr2: [],
      arrKey: [],
      arrKey2: [],
      xx: [],
      yy: [],
      options2: [
        {
          value: "generalAccount",
          label: "对公账户（总）"
        },
        {
          value: "publicAccount",
          label: "对公账户（分）"
        },
        {
          value: "taobao",
          label: "淘宝"
        },
        {
          value: "tiktok",
          label: "抖音"
        },
        {
          value: "pinduoduo",
          label: "拼多多"
        },
        {
          value: "bankCard",
          label: "银行卡"
        },
        {
          value: "alipay",
          label: "支付宝"
        },
        {
          value: "wechat",
          label: "微信"
        },
        {
          value: "cash",
          label: "现金"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      options3: [
        {
          value: "wages",
          label: "工资"
        },
        {
          value: "reimbursement",
          label: "报销"
        },
        {
          value: "supplierSettlement",
          label: "供应商结算"
        },
        {
          value: "refund",
          label: "退款"
        },
        {
          value: "marketingManagement",
          label: "市场营销"
        },
        {
          value: "fine",
          label: "罚款"
        },
        {
          value: "public relationsExpenses",
          label: "公关费"
        },
        {
          value: "rent",
          label: "房租水电成本"
        },
        {
          value: "introduction",
          label: "介绍费"
        },
        {
          value: "tax",
          label: "税费"
        },
        {
          value: "socialSecurity",
          label: "社保费"
        },
        {
          value: "development",
          label: "研发费用"
        },
        {
          value: "other",
          label: "其他"
        }
      ]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    value(val,oldVal){
      if(val != oldVal){
        this.drawBar()
      }
    }
  },
  //方法集合
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    drawBar() {
      // echarts柱子图
      let x = this.option.xAxis.data; //X轴
      let totData = []; //数据

      if (this.barMain[0] == "barMain2") {
        //财务
        this.arr = ["营业利润比","营业支出类型占比"];
        this.arrKey = ["profitProportion","proportion"];
        // this.arr2 = ["月份支出","月份收入","月份利润","支出类型","支出占比"]
        // this.arrKey2 = ["expenditureMonthTotal", "incomeMonthTotal","profitMonthTotal","expenditureType","proportion"];
        if(this.value == 1){
          x = []
          if (this.homeData.profitOrder) {
            this.homeData.profitOrder.map((item) => {
              x.push(item.month); //X
            });
          }
          
        }else{
          x = []
          if (this.homeData.expenditureOrder) {
            this.homeData.expenditureOrder.map((item) => {
              x.push(item.expenditureType == "wages"? "工资":item.expenditureType =="reimbursement"
                      ? "报销"
                      :item.expenditureType =="supplierSettlement"
                      ? "供应商结算"
                      :item.expenditureType =="refund"
                      ? "退款"
                      :item.expenditureType =="marketingManagement"
                      ? "市场营销":item.expenditureType =="fine"
                      ? "罚款":item.expenditureType =="publicRelationsExpenses"
                      ? "公关费":item.expenditureType =="rent"
                      ? "房租水电成本":item.expenditureType =="introduction"? "介绍费":
                      item.expenditureType =="tax"?"税费":item.expenditureType =="socialSecurity"?"社保费":
                      item.expenditureType =="development"? "研发费用":"其他"); //X
            });
          }
        }
        this.xx = x;
      } else {
        //销售
        this.arr = ["项目服务每月总咨询量", "项目服务每月产能金额"];
        this.arrKey = ["consultationNum", "price"];
        x = []
        if (this.homeData.projectOrder) {
          this.homeData.projectOrder.map((item) => {
            x.push(item.month); //X
          });
        }
      }

      if (x && x.length) {
        // 设置X轴
        this.option.xAxis.data = x
      }

      this.arr.map((arrItem, arrIndex) => {
        totData.push({
          //Y
          data: [], //数据
          name: arrItem, //图例
          type: "bar",
          barWidth: 40, //柱图宽度
        });

        if (this.barMain[0] == "barMain2") {
          //财务
          if (this.value == 1) {
            if( this.homeData.profitOrder) {
              this.homeData.profitOrder.map((item) => {
                let obj = {...item}
                delete obj.month
                obj.value = item.profitProportion
                delete obj.profitProportion
                totData[arrIndex].data.push(obj);
                totData[arrIndex].itemStyle= { //柱子颜色
                  color: '#1890ff'
                },
                // totData[arrIndex].stack= 'total',
                this.yy = totData;
              });
            }
          }else{
            if (this.homeData.expenditureOrder) {
              this.homeData.expenditureOrder.map((item) => {
                let obj = {...item}
                delete obj.proportion
                obj.value = item.proportion
                // delete obj.expenditure
                totData[arrIndex].data.push(obj);
                totData[arrIndex].itemStyle= { //柱子颜色
                  color: '#FF4D4F'
                },
                // totData[arrIndex].stack= 'total', //多个柱子合并一个柱子
                this.yy = totData;
              });
            }
          }
        } else {
          //销售
          if (this.homeData.projectOrder) {
            this.homeData.projectOrder.map((item) => {
              totData[arrIndex].data.push((item[this.arrKey[arrIndex]]).toFixed(2));
            });
          }
        }

        // 提示格式化显示
        if(this.barMain[0]=='barMain2'){
          if(this.value == 1){
            this.option.tooltip = { 
              trigger: "axis",
              formatter: function(params) {
                return params[0].name + '</br>' + '毛利润比：' + (params[0].value+'%') + '</br>' + '累计收入：' + (params[0].data.incomeMonthTotal)	 + '</br>' + '累计支出: ' + (params[0].data.expenditureMonthTotal) + '<br/>'  + '毛利润金额：' + (params[0].data.profitMonthTotal)
              }
            }
          }else{
            this.option.tooltip = { 
              trigger: "axis",
              formatter: function(params) {
                return params[0].name +': '+ params[0].data.expenditure + '</br>' + '在总支出中的占比: ' + ((params[0].value).toFixed(2) + '%') + '</br>'
              }
            }
          }
        }
      

        let echartsDataA = totData.slice(0, 1);
        let echartsDataB = totData.slice(1, 2);

        this.echartsLine(
          echartsDataA,
          this.barMain[0] == "barMain2"
            ? ["营业利润比"]
            : ["项目服务每月总咨询量"],
          0
        );
        this.echartsLine(
          echartsDataB,
          this.barMain[0] == "barMain2"
            ? ["营业支出类型占比"]
            : ["项目服务每月产能金额"],
          1
        );
      });
    },
    echartsLine(echartsY, items, num) {
      this.option.series = echartsY;
      this.option.legend.data = items;
      
      let myChart = this.$echarts.init(
        document.getElementById(this.barMain[num])
      );
      myChart.setOption(this.option);
      ////////////////////////////////
      var mainChart = document.getElementById(this.barMain[num]);
      let charts = this.$echarts.init(mainChart); // 图标ID初始化--使用它的resize()
      // 初始化element-resize-detector组件
      let erd = elementResizeDetectorMaker({
        strategy: "scroll", // <- 推荐监听滚动，提升性能
        callOnAdd: true // 添加侦听器时是否应调用,默认true
      })
      erd.listenTo(mainChart, function() {
        // console.log(element,'........');
        charts.resize(); // 当元素尺寸发生改变是会触发此事件，刷新图表
      });
    },
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr,mode) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      if (mode == "payout"|| mode == "income") {
        //财务
        this.$refs.commonSumDialog.getAllData(
          type,
          name,
          screen,
          arr,
          this.comId,
          "",
          this.options3,
          this.options2
        );
      } else {
        this.$refs.commonSumDialog.getAllData(
          type,
          name,
          screen,
          arr,
          this.comId,
          "",
          "",
          "",
          "",
          this.activeRadio
        );
      }
      this.commonSumVisible = true;
    }
    // 数据汇总弹窗
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.drawBar();
  }
};
</script>

<style lang="less" scoped>
.bar-container {
  width: 100%;
  .body{
    display: flex;
    width: 1620px;
  }
  .barMain {
    display: inline-block;
    width: 1170px;
    height: 563px;
  }
  .shopNum {
    box-sizing: border-box;
    display: inline-block;
    width: 405px;
    margin-left: 70px;
    padding: 0 0 32px 32px;
    .publicTit {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      margin-bottom: 32px;
    }
    .con {
      li {
        margin-top: 16px;
        box-sizing: border-box;
        height: 48px;
        background: #f2f9ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        .logo {
          margin-right: 16px;
        }
      }
    }
  }
  .shopNum2 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 376px;
    margin-left: 70px;
    background: #f2f9ff;
    border-radius: 8px;
    padding: 32px;
    li {
      width: 312px;
      height: 120px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 100);
      &:nth-child(-n + 2) {
        margin-bottom: 32px;
      }
      .left {
        display: inline-block;
        width: 32px;
        height: 120px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px 0px 0px 8px;
        color: #fff;
        font-size: 14px;
        writing-mode: vertical-rl;
        background-color: rgba(24, 144, 255, 100);
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        .mon {
          color: rgba(51, 51, 51, 100);
          font-size: 14px;
        }
        /deep/.money {
          text-decoration: underline;
          text-align: left;
          span {
            color: rgba(24, 144, 255, 100);
            font-size: 28px;
          }
        }
        .money3 {
          display: block;
          color: rgba(51, 51, 51, 100);
          font-size: 28px;
        }
      }
      .left2 {
        display: inline-block;
        width: 32px;
        height: 120px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px 0px 0px 8px;
        color: #fff;
        font-size: 14px;
        writing-mode: vertical-rl;
        background-color: #ff4d4f;
      }
      .right2 {
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        .mon {
          color: rgba(51, 51, 51, 100);
          font-size: 14px;
        }
        /deep/.money {
          text-align: left;
          text-decoration: underline;
          text-decoration-color: #ff4d4f;
          span {
            color: #ff4d4f;
            font-size: 28px;
          }
        }
      }
    }
  }

  .charts {
    width: 200px;
    /deep/.el-input__inner {
      color: #1890ff;
      background: #f2f9ff;
      border: none;
    }
    /deep/ .el-select__caret {
      color: #1890ff;
    }
  }
}
</style>
